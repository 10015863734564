import "bootstrap/dist/css/bootstrap.css";
import { Card, Nav, Navbar, Row, Col, Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";

function NavBarDownAzatot() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        color: white,
        fontSize: "20px",
        backgroundColor: black,
      }}>
      <div className=" text-secondary">
        <Navbar
          className="justify-content-center"
          variant="dark"
          sticky="top"
          expand="lg"
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "20px",
          }}>
          <Nav
            className="justify-content-center"
            //activeKey="/home"
            variant="dark"
            sticky="top"
            expand="lg"
            style={{
              fontFamily: "BebasNeue-Regular",
              color: white,
              fontSize: "20px",
            }}>
            <Nav.Item>
              <Nav.Link
                href="/home"
                style={{
                  fontFamily: "BebasNeue-Regular",
                  color: white,
                }}>
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/games"
                style={{
                  fontFamily: "BebasNeue-Regular",
                  color: white,
                }}>
                Games
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/apps"
                style={{
                  fontFamily: "BebasNeue-Regular",
                  color: white,
                }}>
                Apps
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/studio"
                style={{
                  fontFamily: "BebasNeue-Regular",
                  color: white,
                }}>
                Studio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="/contact"
                style={{
                  fontFamily: "BebasNeue-Regular",
                  color: white,
                }}>
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
        <Container
          className=" text-secondary justify-content-center"
          style={{
            fontSize: 18,
            fontFamily: "BebasNeue-Regular",
            color: white,
          }}>
          <Row className="justify-content-md-center">
            <Col
              md="auto"
              style={{
                fontFamily: "BebasNeue-Regular",
                color: white,
              }}>
              COPYRIGHT © 2023 AzatotGames Oy
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default NavBarDownAzatot;
