import React from "react";
import {
  Accordion,
  Card,
  Button,
  Container,
  Row,
  Col,
  Nav,
  Alert,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("click :3")
  );

  return (
    <Button variant="dark" size="lg" onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}
const dark_blue = "#0C0032";
const dark_purple = "#190061";
const purple = "#240090";
const blue = "#3500D3";
const grey = "#282828";
const black = "#000000";
const white = "#ffffff";
document.title = "Azatotgames";

function ContactPage() {
  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}
      className="p-2 gap-3 ">
      <Container>
        <div
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}
          className="p-2 gap-3  justify-content-center ">
          <h1 className="d-flex justify-content-center ">Contacts</h1>
        </div>
      </Container>
      <Container className=" p-3 gap-3 ">
        <Alert variant="info" style={{ fontSize: "25px" }}>
          Information about company or questions: info@azatotgames.com
        </Alert>
        <Row
          className="d-flex justify-content-center "
          style={{ fontSize: "20px" }}>
          <Accordion
            defaultActiveKey="0"
            className="
             w-100 ">
            <Card className="bg-secondary text-dark w-100 ">
              <Card.Header>
                <CustomToggle eventKey="0">Business</CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <h3>Business email</h3>
                        <p>We are open to new ideas, projects and people.</p>
                      </Col>
                      <Col md="auto">
                        <Button
                          variant="info"
                          href="mailto:info@azatotgames.com">
                          <a>Business email</a>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="bg-secondary text-dark w-100 ">
              <Card.Header>
                <CustomToggle eventKey="1">Support</CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Container>
                    <Row>
                      <Col>
                        <h3>Support</h3>
                        <p>
                          If you need support or you have questions about our
                          products.
                        </p>
                      </Col>
                      <Col md="auto">
                        <Button
                          variant="info"
                          href="mailto:support@azatotgames.com">
                          <a>Support email</a>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Row>
      </Container>
    </div>
  );
}

export default ContactPage;
