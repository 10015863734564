import {
  Col,
  Nav,
  Container,
  Row,
  Media,
  Image,
  Card,
  Carousel,
  Button,
  Table,
} from "react-bootstrap";
import React from "react";
import MainPageCarouselAza from "./CarouselMain";

const ArtemiiPhoto =
  "http/s://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o_fullhd.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";
const dark_blue = "#0C0032";
const dark_purple = "#190061";
const purple = "#240090";
const blue = "#3500D3";
const grey = "#282828";
const black = "#000000";
const white = "#ffffff";
document.title = "Azatotgames";

function StudioPageAza() {
  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}
      className="p-2 gap-3 ">
      <Container>
        <Row className="justify-content-md-center">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/kotka_fullhd.jpg"
                alt="First slide"
                //style={{ maxWidth: 1200 }}
              />
              <Carousel.Caption className=" p-2 gap-3">
                <Card className="text-dark bg-secondary">
                  <Card.Body
                    className="p-2 gap-3"
                    style={{
                      fontFamily: "BebasNeue-Regular",
                      backgroundColor: dark_purple,
                      color: white,
                      fontSize: "18px",
                    }}>
                    <h3>Oulu</h3>
                    <p>Main office located in Oulu.</p>
                  </Card.Body>
                </Card>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/kotka2_fullhd.jpg"
                alt="Second slide"
              />

              <Carousel.Caption className="p-2 gap-3">
                <Card className="text-dark bg-secondary">
                  <Card.Body
                    style={{
                      fontFamily: "BebasNeue-Regular",
                      backgroundColor: dark_purple,
                      color: white,
                      fontSize: "18px",
                    }}>
                    <p></p>
                  </Card.Body>
                </Card>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="/kotka3_fullhd.jpg"
                alt="Third slide"
              />

              <Carousel.Caption className=" p-2 gap-3">
                <Card className="text-dark bg-secondary">
                  <Card.Body
                    style={{
                      fontFamily: "BebasNeue-Regular",
                      backgroundColor: dark_purple,
                      color: white,
                      fontSize: "18px",
                    }}>
                    <p></p>
                  </Card.Body>
                </Card>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
      <p></p>
      <div style={{}}>
        <Container
          className="p-2 gap-3"
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}>
          <Row className="d-flex justify-content-center ">
            <h2>Our team</h2>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col>
              <Table striped bordered hover variant="dark" responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Projects</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="artemii_korshunov">Artemii korshunov</a>
                    </td>
                    <td>Game derector and Lead programmer</td>
                    <td>Bozalleth's Curse, Funny Fish,</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="nikolay_batarshin">Nikolay Batarshin</a>
                    </td>
                    <td>SMM and Game localization</td>
                    <td> Bozalleth's Curse, Funny Fish</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

        <div className="gap-3 p-3">
          <div
            className="p-2 gap-3"
            style={{
              fontFamily: "BebasNeue-Regular",
              backgroundColor: dark_purple,
              color: white,
              fontSize: "18px",
            }}>
            <Row className="justify-content-md-center p-2 gap-3">
              <Col md="auto" className="p-2 gap-3">
                <Image
                  src="/kotka4_fullhd.jpg"
                  rounded
                  width={250}
                  height={370}
                />
              </Col>
              <Col className="p-2 gap-3">
                <h5>Where are we?</h5>
                <p>Main office located in Oulu.</p>
                <Button variant="info" href="mailto:info@azatotgames.com">
                  <a>Business email</a>
                </Button>
              </Col>
            </Row>
          </div>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default StudioPageAza;
