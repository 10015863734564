import {
  Col,
  Nav,
  Container,
  Row,
  Media,
  Image,
  Card,
  Carousel,
  Button,
  Tab,
} from "react-bootstrap";
import React from "react";
import MainPageCarouselAza from "./CarouselMain";

const ArtemiiTex =
  "The human being is inexplicable and difficult to understand, each of us is afraid of something at some point. Almost all people are afraid of darkness, they see in it danger, monsters, terrible things. Are you afraid of darkness and the monsters that live in              it? Do you dare to test yourself?";
const ArtemiiPhoto =
  "https://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";

function AppsPageAza() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  document.title = "Apps - Azatotgames";

  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}
      className="p-2 gap-3 ">
      <Container>
        <div
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}
          className="p-2 gap-3  justify-content-center ">
          <h1 className="d-flex justify-content-center ">Apps</h1>
          <title className="d-flex justify-content-center ">
            Our apps projects
          </title>
        </div>
      </Container>
      <div
        className="p-2 gap-3"
        style={{
          fontFamily: "BebasNeue-Regular",
          backgroundColor: black,
          color: white,
          fontSize: "18px",
        }}>
        <div
          className="p-2 gap-3"
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "20px",
          }}>
          <Container className=" p-3 gap-3 ">
            <Row className="d-flex justify-content-center ">
              <Col ms="auto">
                <Container>
                  <Carousel>
                    <Carousel.Item>
                      <img src="/cars.png" alt="1 slide" />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="/customers.png" alt="2 slide" />

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="/drivers.png" alt="3 slide" />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="/shifts.png" alt="4 slide" />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="/shift_2.png" alt="5 slide" />
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </Container>
              </Col>
              <Col ms="auto" style={{ minWidth: "300px" }}>
                <Row className="p-2 gap-3">
                  <Col xs lg="2">
                    <Image
                      src="/driveshift_logo.png"
                      rounded
                      style={{ width: 70, height: 70 }}
                    />
                  </Col>
                  <Col>
                    <h1>DriveShift</h1>
                  </Col>
                </Row>
                <p>
                  App allows you to conveniently and quickly create and track
                  shifts drivers. Also view cars and customers. Convenient
                  calendar for choosing the beginning and end of the shift, the
                  ability to select the day and night shift. Dial the phone
                  number of the driver and the customer. Shift reporting
                  function.
                </p>
                <p>
                  App contains information about cars, drivers, customer and
                  shifts. You can easily fill in information about this.
                </p>
                <p></p>

                <Nav className="justify-content-end p-2 gap-3">
                  <Button
                    style={{ background: black }}
                    variant="dark"
                    size="lg"
                    href="https://play.google.com/store/apps/details?id=aza.azatotDrive.my_shifts_navidation">
                    GooglePlay
                  </Button>
                </Nav>
              </Col>
            </Row>
            <p></p>
            <Row className="bg-second">
              <Col>
                <div className="bg-dark text-secondary">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="Cars">
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="Cars">Cars</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Drivers">Drivers</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Customer">Customer</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Shifts">Shifts</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          <Tab.Pane eventKey="Cars">
                            <ul>
                              <li>List of cars.</li>
                              <li>
                                Interface for easy creation and conversion of
                                vehicle information.
                              </li>
                            </ul>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Customer">
                            <ul>
                              <li>List of client companies</li>
                              <li>2 phone numbers of companies</li>
                              <li>
                                Also the ease and convenience of the interface
                              </li>
                            </ul>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Drivers">
                            <ul>
                              <li>List of drivers.</li>
                              <li>
                                Interface for easy creation and conversion of
                                driver information.
                              </li>
                            </ul>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Shifts">
                            <ul>
                              <li>Shift list.</li>
                              <li>
                                Ability to select drivers, cars, customers from
                                the lists.
                              </li>
                              <li>
                                Assigning the start and end date of the shift.
                              </li>
                              <li>Ability to send shift reports.</li>
                              <li>Dial the number of the driver or company.</li>
                            </ul>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default AppsPageAza;
