import {
  Col,
  Nav,
  Container,
  Row,
  Media,
  Image,
  Card,
  Carousel,
  Button,
  Alert,
} from "react-bootstrap";
import React from "react";
import MainPageCarouselAza from "./CarouselMain";

const ArtemiiTex =
  "The human being is inexplicable and difficult to understand, each of us is afraid of something at some point. Almost all people are afraid of darkness, they see in it danger, monsters, terrible things. Are you afraid of darkness and the monsters that live in              it? Do you dare to test yourself?";
const ArtemiiPhoto =
  "https://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";

function GamesAzatot() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  document.title = "Games - Azatotgames";

  return (
    <div
      className=" p-2 gap-3"
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
      }}>
      <Container>
        <div
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}
          className="p-2 gap-3  justify-content-center ">
          <h1 className="d-flex justify-content-center ">Games</h1>
        </div>
      </Container>
      <br></br>
      <div
        className="p-2 gap-3"
        style={{
          fontFamily: "BebasNeue-Regular",
          color: white,
          fontSize: "25px",
        }}>
        <Row className="justify-content-md-center">
          <Col sm={6} className="p-2 gap-3">
            <h1>Bozalleth's Curse</h1>
            <p style={{ color: white }}>
              Nightmares that wake you up in cold sweat. The world of dreams
              hides secrets and your sins. The game 'Bozalleth's Curse' tells
              you the story of Mary, who had an unfortunate accident eight years
              ago.
            </p>
            <Alert variant="info">Early Access Game.</Alert>

            <Nav className="justify-content-end" activeKey="/home">
              <Button
                variant="dark"
                size="lg"
                href="https://store.steampowered.com/app/2264490/Bozalleths_Curse/">
                Steam Page
              </Button>
            </Nav>
          </Col>
          <Col md="auto" className="p-2 gap-3">
            <Carousel fade style={{ maxWidth: 600 }} className="bg-secondary">
              <Carousel.Item>
                <img className="d-block w-100" src="/1.jpg" alt="First slide" />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/4.jpg"
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src="/5.jpg" alt="Third slide" />

                <Carousel.Caption>
                  <h3></h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </div>
      <br></br>
      <div
        className="gap-3  "
        style={{
          fontFamily: "BebasNeue-Regular",
          minHeight: "100vh",
        }}>
        <div
          className="p-2 gap-3"
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}>
          <Row className="justify-content-md-center">
            <Col md="auto" className="p-2 gap-3">
              <Carousel fade style={{ maxWidth: 600 }}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/fish_screen_1.png"
                    alt="First slide"
                  />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/fish_screen_0.png"
                    alt="Second slide"
                  />

                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/fish_screen_2.png"
                    alt="Second slide"
                  />

                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={6} className="p-2 gap-3" s>
              <h1>Funny Fish</h1>
              <p>
                This is an interesting fascinating game, designed to develop
                fine motor skills in a timekiller format. The action unfolds in
                a pond, where you have to catch as many fish as possible. You
                can perform missions in a daily format and get achievements and
                points.
              </p>
            </Col>
          </Row>
          <Nav className="justify-content-end d-flex " activeKey="/home">
            <Button
              variant="dark"
              size="lg"
              href="https://apps.apple.com/fi/app/funny-fish-2d-gold/id1564645281">
              AppStore
            </Button>

            <Button
              variant="dark"
              size="lg"
              href="https://play.google.com/store/apps/details?id=com.DefaultCompany.Funny_Fish">
              GooglePlay
            </Button>

            <Button
              variant="dark"
              size="lg"
              href="https://yandex.ru/games/play/162220/?app-id=162220#app-id=162220&catalog-session-uid=catalog-4cab89d1-15b8-547a-86d4-969a6b41c5bc-1620338095847-20c7&rtx-reqid=2328193017099975176">
              YandexGames
            </Button>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default GamesAzatot;
