import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import GamesAzatot from "./basic/Pages/GamesAza"; // Updated import path
import AppsPageAza from "./basic/Pages/AppsPage"; // Updated import path
import NavBarDownAzatot from "./basic/Navbar/NavBarDown";
import NavBarMobAzatot from "./basic/Navbar/MobileNavBar";
import StudioPageAza from "./basic/Pages/StudioPage";
import FunnyFishPolicyHtml from "./basic/Pages/FunnyFishPolicy";
import Error from "./basic/Pages/error404";
import Artemii from "./basic/Pages/team/ArtemiiKorshunov";
import Nikolay from "./basic/Pages/team/NikolayBatarshin";
import FunnyFish from "./basic/Pages/games/funnyfish";
import ContactPage from "./basic/Pages/ContactPage";
import MainPageAza from "./basic/Pages/MainPaige";

function App() {
  return (
    <>
      <header className="App-header">
        <head>
          <script
            data-ad-client="ca-pub-1052890017326335"
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        </head>

        <NavBarMobAzatot></NavBarMobAzatot>
        <Router>
          <div style={{ minHeight: "100vh" }}>
            <Routes>
              <Route path="/home" element={<MainPageAza />} />
              <Route path="/studio" element={<StudioPageAza />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/games" element={<GamesAzatot />} />
              <Route path="/apps" element={<AppsPageAza />} />
              <Route path="/artemii_korshunov" element={<Artemii />} />
              <Route path="/nikolay_batarshin" element={<Nikolay />} />
              <Route path="/funny_fish" element={<FunnyFish />} />
              <Route
                path="/funny_fish_policy"
                element={<FunnyFishPolicyHtml />}
              />
              <Route path="/404" element={<Error />} />
              <Route path="/" element={<MainPageAza />} />{" "}
              <Route path="/*" element={<Navigate to="/404" />} />{" "}
              {/* Handle unmatched routes */}
            </Routes>
          </div>
        </Router>
        <NavBarDownAzatot></NavBarDownAzatot>
      </header>
    </>
  );
}

export default App;
