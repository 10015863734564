import {
  Col,
  Nav,
  Container,
  Row,
  Media,
  Image,
  Card,
  Carousel,
  Button,
  Alert,
  ListGroup,
  Table,
} from "react-bootstrap";
import React from "react";

const ArtemiiTex =
  "The human being is inexplicable and difficult to understand, each of us is afraid of something at some point. Almost all people are afraid of darkness, they see in it danger, monsters, terrible things. Are you afraid of darkness and the monsters that live in              it? Do you dare to test yourself?";
const ArtemiiPhoto =
  "https://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";

function FunnyFish() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  document.title = "Funny Fish - Azatotgames";

  return (
    <div
      className=" p-2 gap-3"
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}>
      <div
        className="p-2 gap-3"
        style={{
          fontFamily: "BebasNeue-Regular",
          backgroundColor: black,
          minHeight: "100vh",
        }}>
        <div
          className="p-2 gap-3"
          style={{
            fontFamily: "BebasNeue-Regular",
            backgroundColor: dark_blue,
            color: white,
            fontSize: "25px",
          }}>
          <Container>
            <Row className="d-flex justify-content-center ">
              <Col md="auto">
                <div style={{ maxWidth: 350 }}>
                  <Image
                    src="/Funny_fish_logo_2.png"
                    className="img-fluid"
                    fluid
                    rounded
                  />
                </div>
              </Col>

              <Col xs lg="17">
                <ListGroup variant="dark" className="p-2 gap-3">
                  <ListGroup.Item variant="dark">
                    <h1>Funny Fish</h1>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <Row className="d-flex ">
                      <Col md="auto">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.DefaultCompany.Funny_Fish"
                          target="_blank">
                          <img
                            style={{ maxWidth: 35 }}
                            src="/google_play.png"></img>
                        </a>
                      </Col>
                      <Col md="auto">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.DefaultCompany.Funny_Fish"
                          target="_blank">
                          <h2>Google Play</h2>
                        </a>
                      </Col>
                      <Col md="auto">
                        <a
                          href="https://apps.apple.com/fi/app/funny-fish-2d-gold/id1564645281"
                          target="_blank">
                          <img
                            style={{ maxWidth: 35 }}
                            src="/appstore.png"></img>
                        </a>
                      </Col>
                      <Col md="auto">
                        <a
                          href="https://apps.apple.com/fi/app/funny-fish-2d-gold/id1564645281"
                          target="_blank">
                          <h2>AppStore</h2>
                        </a>
                      </Col>
                      <Col md="auto">
                        <a
                          href="https://yandex.ru/games/play/162220/?app-id=162220#app-id=162220&catalog-session-uid=catalog-4cab89d1-15b8-547a-86d4-969a6b41c5bc-1620338095847-20c7&rtx-reqid=2328193017099975176"
                          target="_blank">
                          <img
                            style={{ maxWidth: 35 }}
                            src="/controller.png"></img>
                        </a>
                      </Col>
                      <Col md="auto">
                        <a
                          href="https://yandex.ru/games/play/162220/?app-id=162220#app-id=162220&catalog-session-uid=catalog-4cab89d1-15b8-547a-86d4-969a6b41c5bc-1620338095847-20c7&rtx-reqid=2328193017099975176"
                          target="_blank">
                          <h2>YandexGames</h2>
                        </a>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <h2>RELEASE DATE: 19 April, 2021</h2>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <p>
                      Description. Our game is in the classic sense of
                      Time-Wasting, a game you can play while standing in a
                      queue or waiting for your bus. Our game can play and have
                      a good time people of any age group, but the main age
                      group is children and teenagers.
                    </p>
                    <br></br>
                    <p>
                      instructions. The whole game is built on collecting fish,
                      but be careful, there are both regular and exploding fish.
                      The player has 3 lives, but for each fish that floats away
                      or explodes, a life is taken away from the player, if the
                      life count reaches 0, the player loses. The main goal is
                      to reach a new level and complete missions.
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    DEVELOPER: AzatotGames
                    <br></br>
                    game designer and lead programmer:
                    <a href="/artemii_korshunov">Artemii Korshunov</a>
                    <br></br>
                    programmers:{" "}
                    <a
                      href="/nikolay_batarshin
  ">
                      Nikolay Batarshin
                    </a>
                    ,{" "}
                    <a
                      href="/markus_heikkila
">
                      Markus Heikkilä
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </div>

        <p></p>
      </div>
    </div>
  );
}

export default FunnyFish;
