import {
  Image,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Card,
  Button,
  Form,
  FormControl,
  Container,
} from "react-bootstrap";

function NavBarMobAzatot() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";

  return (
    <Navbar
      variant="dark"
      sticky="top"
      expand="lg"
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        color: white,
        fontSize: "20px",
      }}>
      <Container>
        <Navbar.Brand
          href="/home"
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}>
          <Image
            src="/azatotLogo2.png"
            alt=""
            width="90"
            height="70"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="reasponsice-navbar-nav" />
        <Navbar.Collapse id="mobile-nav">
          <Nav>
            <NavLink
              className="nav-link"
              href="/games"
              style={{
                fontFamily: "BebasNeue-Regular",
                color: white,
              }}>
              {" "}
              Games{" "}
            </NavLink>

            <NavLink
              className="nav-link"
              href="/apps"
              style={{
                fontFamily: "BebasNeue-Regular",
                color: white,
              }}>
              {" "}
              Apps{" "}
            </NavLink>

            <NavLink
              className="nav-link"
              href="/studio"
              style={{
                fontFamily: "BebasNeue-Regular",
                color: white,
              }}>
              {" "}
              Studio{" "}
            </NavLink>

            <NavLink
              className="nav-link"
              href="/contact"
              style={{
                fontFamily: "BebasNeue-Regular",
                color: white,
              }}>
              {" "}
              Contact{" "}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarMobAzatot;
