import {
  Col,
  Nav,
  Container,
  Row,
  Media,
  Image,
  Card,
  Carousel,
  Button,
  Alert,
  ListGroup,
  Table,
} from "react-bootstrap";
import React from "react";

const ArtemiiTex =
  "The human being is inexplicable and difficult to understand, each of us is afraid of something at some point. Almost all people are afraid of darkness, they see in it danger, monsters, terrible things. Are you afraid of darkness and the monsters that live in              it? Do you dare to test yourself?";
const ArtemiiPhoto =
  "https://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";

function Nikolay() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  document.title = "Nikolay Batarshin - Azatotgames";

  return (
    <div
      className=" p-2 gap-3"
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}>
      <div
        className="p-2 gap-3"
        style={{
          fontFamily: "BebasNeue-Regular",
          backgroundColor: black,
          minHeight: "100vh",
        }}>
        <div
          className="p-2 gap-3"
          style={{
            fontFamily: "BebasNeue-Regular",
            backgroundColor: dark_blue,
            color: white,
            fontSize: "25px",
          }}>
          <Container>
            <Row className="d-flex justify-content-center ">
              <Col md="auto">
                <div style={{ maxWidth: 380 }}>
                  <Image
                    alt="Nikolay Batarshin"
                    src="/nikolay.jpg"
                    className="img-fluid"
                    fluid
                    rounded
                  />
                </div>
              </Col>

              <Col xs lg="17">
                <ListGroup variant="dark" className="p-2 gap-3">
                  <ListGroup.Item variant="dark">
                    <h1>Nikolay Batarshin</h1>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <h2>SMM and Game localization</h2>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <Container>
                      <Row>
                        <h2>
                          Saint Petersburg State University Of Architecture and
                          civil engineering
                        </h2>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <h3>
                      Monkeys are strong together and we will prove it. I love
                      developing gaming products on social networks.
                    </h3>
                  </ListGroup.Item>
                  <ListGroup.Item variant="dark">
                    <Row className="d-flex justify-content-center ">
                      <Col md="auto">
                        <a href="mailto:nikolay.batarshin@azatotgames.com">
                          <img src="/email.png" style={{ maxWidth: 40 }}></img>
                          email
                        </a>
                        - nikolay.batarshin@azatotgames.com
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center ">
              <Table striped bordered hover variant="dark" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Project</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <a href="funny_fish">Funny fish</a>
                    </td>{" "}
                    <td>Qa tester and programmer</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Bozalleth's Curse</td>{" "}
                    <td>qa tester and game localization</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Container>
        </div>

        <p></p>
      </div>
    </div>
  );
}

export default Nikolay;
