import { useState } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Badge,
  Image,
  Carousel,
  Button,
  Alert,
  Tab,
  Nav,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";

function Error() {
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        height: "100vh",
      }}
      className="p-2 gap-3">
      <Row className="d-flex justify-content-center ">
        <Col xs="auto">
          <br></br>
          <br></br>
          <br></br>

          <div
            style={{
              backgroundColor: dark_purple,
              color: white,
            }}>
            <h1
              style={{ color: white }}
              className="d-flex justify-content-center ">
              Error 404
            </h1>
            <p>Not Found </p>
          </div>
        </Col>
        <Col xs="auto">
          <div style={{ maxWidth: 900 }}>
            <Image src="cheems.png" className="img-fluid" fluid rounded />
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Error;
