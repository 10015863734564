import { useState } from "react";
import {
  Col,
  Container,
  Row,
  Media,
  Image,
  Card,
  Tabs,
  Tab,
  TabContainer,
} from "react-bootstrap";
import MainPageCarouselAza from "../Pages/CarouselMain";
import ReactPlayer from "react-player";

const ArtemiiTex =
  "The human being is inexplicable and difficult to understand, each of us is afraid of something at some point. Almost all people are afraid of darkness, they see in it danger, monsters, terrible things. Are you afraid of darkness and the monsters that live in              it? Do you dare to test yourself?";
const ArtemiiPhoto =
  "https://scontent-arn2-1.xx.fbcdn.net/v/t31.0-8/21743404_1936105033330604_7644073244974283606_o.jpg?_nc_cat=103&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=Y-4zNY66YxYAX_86KXB&_nc_ht=scontent-arn2-1.xx&oh=cc6f4818048ef60db9843113c1a60fa1&oe=6081D4A5";

const KalaTex = "Mahtavat kalat ja Miten toimi multitouch in Unity? \n Nain";

function MainPageAza() {
  const [key, setKey] = useState("home");
  const dark_blue = "#0C0032";
  const dark_purple = "#190061";
  const purple = "#240090";
  const blue = "#3500D3";
  const grey = "#282828";
  const black = "#000000";
  const white = "#ffffff";
  document.title = "Azatotgames";

  return (
    <div
      style={{
        fontFamily: "BebasNeue-Regular",
        backgroundColor: black,
        minHeight: "100vh",
      }}
      className="p-2 gap-3 ">
      <Container>
        <div
          style={{
            fontFamily: "BebasNeue-Regular",
            color: white,
            fontSize: "25px",
          }}
          className="p-2 gap-3  justify-content-center ">
          <h1 className="d-flex justify-content-center ">AzatotGames</h1>
        </div>
      </Container>

      <div
        className="p-2 gap-3"
        style={{
          fontFamily: "BebasNeue-Regular",
          backgroundColor: black,
          color: white,
          fontSize: "18px",
        }}>
        <Row className="justify-content-md-center p-2 gap-3">
          <Col md="auto" className="justify-content-md-center p-2 gap-3">
            <Container>
              <div>
                <ReactPlayer
                  style={{
                    display: "block",
                    maxWidth: "100%",
                  }}
                  url="https://youtu.be/VVDuDKzFqqg?si=Fq3-G91u-32AjNzu"
                />
              </div>
            </Container>
            <p></p>
          </Col>
        </Row>
        <p></p>
        <Row className="justify-content-md-center p-2 gap-3">
          <Col
            className="justify-content-md-center p-2 gap-3  "
            style={{ minWidth: "350px" }}>
            <Container>
              <Row>
                <Col>
                  <div
                    className="p-2 gap-3"
                    style={{
                      fontFamily: "BebasNeue-Regular",
                      color: white,
                      fontSize: "25px",
                    }}>
                    <Row className="justify-content-md-center p-2 gap-3">
                      <Col
                        md="auto"
                        className="justify-content-md-center p-2 gap-3">
                        <h2 className="d-flex justify-content-center p-2 gap-3">
                          News
                        </h2>

                        <h1>
                          <b>Bozalleth's Curse</b>
                        </h1>
                        <p>
                          Nightmares that wake you up in cold sweat. The world
                          of dreams hides secrets and your sins. The game
                          'Bozalleth's Curse' tells you the story of Mary, who
                          had an unfortunate accident eight years ago.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            className="justify-content-md-center p-2 gap-3 w-25 "
            style={{ minWidth: "350px" }}>
            <Container>
              <Row>
                <Col>
                  <div
                    className="p-2 gap-3"
                    style={{
                      fontFamily: "BebasNeue-Regular",
                      color: white,
                      fontSize: "25px",
                    }}>
                    <Row className="justify-content-md-center p-2 gap-3">
                      <Col
                        md="auto"
                        className="justify-content-md-center p-2 gap-3">
                        <h2 className="d-flex justify-content-center p-2 gap-3 ">
                          About us
                        </h2>

                        <text className="d-flex justify-content-md-center">
                          Welcome to the enchanting world of Azatot Games, an
                          independent game developer hailing from the heart of
                          Oulu, Finland. Founded by a passionate group of gamers
                          and creative minds, Azatot Games is committed to
                          crafting immersive and unforgettable gaming
                          experiences that captivate players of all ages.
                        </text>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MainPageAza;
